<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="共有情報詳細" />
    <AppSideMenu />
    <main id="main" class="l-main">
      <div class="l-container">
        <!-- エラーメッセージ表示フィールド -->
        <ErrorMessage :errMsgs="errMsgs" />
        <!-- 基本情報 タイトル -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">基本情報</h3>
          </div>

          <!-- 基本情報 詳細 -->
          <div>
            <!-- ID -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>ID</label>
              </div>
              <div class="c-inputWrap__items">
                {{ shareInfoList.id | blankText }}
              </div>
            </div>

            <!-- 件名 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>件名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ shareInfoList.title | blankText }}
              </div>
            </div>

            <!-- 内容 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>内容</label>
              </div>
              <div class="c-inputWrap__items">
                <span class="textarea">{{ shareInfoList.content | blankText }}</span>
              </div>
            </div>

            <!-- 運搬事業者 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="shareInfoList.collectCompanyInfo">
                  {{ shareInfoList.collectCompanyInfo.name | blankText }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>

            <!-- 処分事業者 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="shareInfoList.disposalCompanyInfo">
                {{ shareInfoList.disposalCompanyInfo.name | blankText }}
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>

            <!-- 担当者 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="shareInfoList.memberInfo">
                  {{ shareInfoList.memberInfo.name | blankText }}
                </template>
              </div>
            </div>

            <!-- 社内サーバーリンクパス -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>社内サーバーリンクパス</label>
              </div>

              <div class="c-inputWrap__items">
                <span class="textarea">{{ shareInfoList.serverLink | blankText }}</span>
              </div>
            </div>

            <!-- 添付ファイル -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>添付ファイル</label>
              </div>

              <div class="c-inputWrap__items">
                <template v-if="shareInfoList.shareInfoFileList && shareInfoList.shareInfoFileList.length != 0">
                  <div
                    v-for="(filelist, index) in shareInfoList.shareInfoFileList"
                    :key="`download-file-${index}`"
                    class="c-input"
                  >
                    <div class="c-inputWrap__items__cols center search-wrap">
                      <a class="c-text text-link c-entrustText__text" @click="downloadFile(filelist)"> 
                        {{ filelist.fileName | blankText }}
                      </a>
                    </div>
                  </div>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>

            <!-- 次回更新日 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>次回更新日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ shareInfoList.nextUpdateDate | standardDateFormatJpYMDdd }}
              </div>
            </div>

            <!-- 次回更新日 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ shareInfoList.createDate | standardDateFormatJpYMDdd }}
              </div>
            </div>

            <!-- 次回更新日 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>最終更新日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ shareInfoList.updateDate | standardDateFormatJpYMDdd }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <router-link
            to="/generate/share/info"
            class="c-btn secondary small back"
            >一覧へ戻る</router-link
          >
        </div>
        <div class="center">
          <router-link
            :to="`/generate/share/edit/${$route.params.id}`"
            class="c-btn secondary small"
          >
            編集
          </router-link>
          <div
            class="c-btn secondary small"
            @click="isDisplayDeleteModal = true"
          >
            削除
          </div>
        </div>
      </div>

      <!--削除確認モーダル-->
      <div class="c-modal" v-if="isDisplayDeleteModal">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            データを削除します。<br />
            よろしいですか？
          </div>
          <div class="c-modal__foot">
            <div
              class="c-btn secondary small"
              @click="isDisplayDeleteModal = false"
            >
              戻る
            </div>
            <div class="c-btn primary small" @click="deleteHandler()">OK</div>
          </div>
        </div>
      </div>
      <!--削除確認モーダルここまで-->
    </main>
    <AppFooter />
  </div>
</template>

<script>
import callApi from "../mixins/callApi.js";

export default {
  name: "shareinfo-detail",
  components: {
  },
  mixins: [callApi],
  data() {
    return {
      errMsgs: [],
      isDisplayDeleteModal: false,
    };
  },

  methods: {
    // 削除確認モーダルで「OK」を押下した場合、対象データを削除する
    deleteHandler() {
      // 削除API
      this.deleteShareInfoApi(this.$route.params.id, true);
      this.isDisplayDeleteModal = false;
    },
    // ファイルダウンロード押下
    downloadFile(fileInfo) {
      this.downloadFileApi(fileInfo.id, fileInfo.fileName);
    },
  },
  created() {
    this.getShareInfoDetailApi(this.$route.params.id, false);
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  border-bottom: 1px solid #cbd0d8;
  margin: 2rem 0;
  font-size: 1.6rem;
}

.textarea {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.c-disposalSiteList {
  .stretch {
    flex: 1;
  }

  .c-infoList__row {
    display: flex;
  }

  &__item {
    + .c-disposalSiteList__item {
      border-top: 1px solid #ccd0d7;
      padding-top: 5px;
    }

    .c-inputWrap + & {
      margin-top: 15px;
    }

    display: flex;
    margin-top: 10px;

    span {
      + span {
        margin-left: 1em;
      }
      &.name {
        width: 10em;
      }
      &.address {
        width: 25em;
      }
      &.code {
        width: 13em;
      }
    }
  }
}
</style>
